<template>
	<section>
		<b-row class="mb-2">
		    <b-col>
				<Breadcrumb :labels="labels" />				
		    </b-col>		    
		</b-row>		
		<b-row>
		    <b-col md="12">
		    	<div class="relative">
			        <b-tabs card class="caption-add table-custom">                       
			            <b-tab title="Basic Info" active class="overview-tab">	
			            	<validation-observer ref="form_rel" #default="{invalid}" >
				                <b-form @submit.prevent class="profile-frm mb-20 mt-1" style="border-radius: 5px">
				                	<b-row class="align-center">
							            <b-col lg-12>
							            	<div class="text-right mb-2">
							                    <b-button type="button" variant="basic" class="btn-df size-18 black" style="box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25); margin-right: 10px " @click="cancelAdd">Cancel</b-button>

							                    <template v-if="(userData && permission.create)">
								                    <b-button type="button" variant="primary" class="btn-df size-18 black" @click="saveAdd">
								                        Save
								                    </b-button>
								                </template>
								                <template v-else>
								                	<b-button type="button" variant="primary" class="btn-df size-18 black btn-disabled-gray" disabled>
								                        Save
								                    </b-button>
								                </template>
							                </div>
							            </b-col>
							        </b-row>				                
					                <div style="border-top: 1px solid #000000B2;" class="pb-2 mb-10"></div>
					                <div class="frm-basic size-16 black-child d-block">              
				                      	<b-row style="width: 100%; margin: 0;">   
				                          	<b-col lg="12" class="px-0">             
				                              	<b-form-group id="" label="Strategic Plan Name" label-cols-lg="2" class="star-required">     
				                              		<validation-provider
					                                  #default="{ errors }"
					                                  name="Strategic Plan Name"
					                                  vid="name"
					                                  rules="required"                      
					                                >                        
					                                    <b-form-input
					                                    	v-model="fieldsAdd.name"
					                                      	placeholder="Name"
					                                      	:formatter="length100"
					                                    ></b-form-input>
					                                    <small class="text-danger">{{ errors[0] }}</small>
					                                </validation-provider>
				                              	</b-form-group>
				                          	</b-col>	
				                          	<b-col lg="6" class="px-0">             
					                            <b-form-group label="FY Start Year" label-cols-lg="4" class="star-required">
													<div class="calendarIcon">
                              							<svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 24 24"><path fill="WindowText" d="M20 3h-1V1h-2v2H7V1H5v2H4c-1.1 0-2 .9-2 2v16c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 18H4V8h16v13z"/><path fill="none" d="M0 0h24v24H0z"/></svg>
                           							</div>
				                                    <datepicker                           	
				                                    	format="yyyy" 
				                                    	minimum-view="year"
				                                    	v-model="fieldsAdd.start_year"
				                                    	placeholder="Select Year"
				                                    	class="w-100-child select-date fieldCalendar_input"
														required
				                                    >              	
				                                    </datepicker>
					                            </b-form-group>
				                          	</b-col> 	
				                          	<b-col lg="6" class="px-0">             
					                            <b-form-group label="FY End Year" label-cols-lg="4" class="star-required">
													<div class="calendarIcon">
                              							<svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 24 24"><path fill="WindowText" d="M20 3h-1V1h-2v2H7V1H5v2H4c-1.1 0-2 .9-2 2v16c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 18H4V8h16v13z"/><path fill="none" d="M0 0h24v24H0z"/></svg>
                           							</div>
					                                <datepicker  
				                                    	format="yyyy" 
				                                    	minimum-view="year"
				                                    	v-model="fieldsAdd.end_year"
				                                    	placeholder="Select Year"
				                                    	class="w-100-child select-date fieldCalendar_input"
														required
				                                    >              	
				                                    </datepicker>
					                            </b-form-group>
				                          	</b-col> 	 	
				                          	<b-col lg="6" class="px-0">             
					                            <b-form-group label="Status" label-cols-lg="4" class="star-required">	
					                            	<validation-provider
					                                  #default="{ errors }"
					                                  name="Status"
					                                  vid="status"
					                                  rules="required"                      
					                                >  
						                                <v-select
						                                	v-model="fieldsAdd.status"
						                                    label="title"
						                                    :options="selectStatus"
						                                    placeholder="Select Status"
															class="select-status"
					                                    />
					                                    <small class="text-danger">{{ errors[0] }}</small>
					                                </validation-provider>
					                            </b-form-group>
				                          	</b-col> 		                          	
				                          	<b-col lg="12" class="px-0">
					                            <b-form-group label="Strategy Plan Description" label-cols-lg="2" class="star-required" >
					                            	<validation-provider
					                                  #default="{ errors }"
					                                  name="Strategy Plan Description"
					                                  vid="description"
					                                  rules="required"                      
					                                >  
						                            	<b-form-textarea
						                            		v-model="fieldsAdd.description"
						                                    placeholder="Strategy Plan Description"
						                                    rows="4"
						                                    :formatter="length1000"
															style="padding-top: 12px !important"
						                                ></b-form-textarea>
						                                <small class="text-danger">{{ errors[0] }}</small>
						                            </validation-provider>
					                            </b-form-group>
				                          	</b-col>   
				                      	</b-row>
					                </div>
					            </b-form> 
					        </validation-observer>
			            </b-tab>
			            <b-tab title="G/S/P" class="tab-child tab-pt-0" disabled>	
			            </b-tab>                       
			        </b-tabs>     
			    </div>
			</b-col>
		</b-row>
	</section>
</template>
<script>

	import Breadcrumb from '../partial/Breadcrumb';
	import flatPickr from 'vue-flatpickr-component'

	import Datepicker from 'vuejs-datepicker';

    import vSelect from 'vue-select'
    import { ValidationProvider, ValidationObserver } from 'vee-validate'
    import { required, email, integer, min } from '@validations'
    import { togglePasswordVisibility } from '@core/mixins/ui/forms'
    import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

    const moment = require('moment');

	export default {
	  	components: {
	  		Breadcrumb,
	  		flatPickr,
	  		Datepicker,
          	vSelect,
          	ValidationProvider,
          	ValidationObserver,
	  	},
	  	data() {
		    return {
		    	userData: JSON.parse(localStorage.getItem('userData')),
				labels: { "itees-strategic": "" },  
				permission: [],
			    selectStatus: [
			    	{title: 'Active', value: 0},	
			    	{title: 'Inactive', value: 1},		    	
			    	{title: 'Completed', value: 2},
			    ],
			    fieldsAdd: {
			    	name: null,
			    	start_year: null,
			    	end_year: null,
			    	status: null,
			    	description: null
			    },
            }
		},	
		created(){
			this.checkPermission("Strategic Plan")
		},
		methods: {
			length1000(e){
			    return String(e).substring(0,1000)
			},
			length100(e){
			    return String(e).substring(0,100)
			},
			checkPermission(namePage){
				var action = [];
				this.userData?.permission.map(function (value) {
					if(value.module === namePage){
						action = value.actions
					}
				})
				this.permission = action 
			},
			cancelAdd(){
				this.$router.push({ name: 'itees-strategic' })
			},
			saveAdd(){
				this.$refs.form_rel.validate().then(success => {
					if (success){
						let start_year_format = moment(this.fieldsAdd.start_year).format('yyyy')
						let end_year_format = moment(this.fieldsAdd.end_year).format('yyyy')
						// var kim = 
						if(parseInt(end_year_format - start_year_format) > 0){
							let fields = {
			                  	name: this.fieldsAdd.name,
			                  	start_year: start_year_format,
			                  	end_year: end_year_format,
			                  	status: this.fieldsAdd.status.value,
			                  	description: this.fieldsAdd.description,
			                }
			                console.log(fields)

			                this.$store
							.dispatch('project/createStrategicPlan', fields)
							.then(response => {
								this.$toast({
				                      	component: ToastificationContent,
				                      	props: {
				                          title: this.fieldsAdd.name + ' Added!',
				                          variant: 'success',
				                      	},
				                	},
				                	{
				                      	position: 'top-center'
				                	}
				                )
				                this.$router.push({ name: 'itees-strategic' })
							})
							.catch((error) => {
								console.log(error.response)
								this.$toast({
				                      	component: ToastificationContent,
				                      	props: {
				                          title: error.response.data.message,
				                          variant: 'danger',
				                      	},
				                	},
				                	{
				                      	position: 'top-center'
				                	}
				                )
							})
						}
						else{
							this.$toast({
			                      	component: ToastificationContent,
			                      	props: {
			                          title: 'End Year must be greater than Start Year',
			                          variant: 'danger',
			                      	},
			                	},
			                	{
			                      	position: 'top-center'
			                	}
			                )
						}
						
					}
					else {
						this.$toast({
	                        component: ToastificationContent,
	                        props: {
	                          title: 'One or more fields have an error. Please check and try again.',
	                          variant: 'danger',
	                        },
	                      },
	                      {
	                        position: 'top-center'
	                      }
	                    )
					}
				})
			}
		},
	};	

</script>

<style lang="scss">
	@import '@core/scss/vue/libs/vue-flatpicker.scss';
	@import "@/assets/scss/_ite_tab_table.scss";

	.Vue-Toastification__container.top-center {
	    top: 20px; 
	}
	.select-status svg {
    width: 25px;
    height: 20px;
	}
	.select-date input::placeholder{
		color: #B1B1B1;
	}
</style>